import React, {useState, useEffect, useCallback}   from "react";
import axios                                       from "axios";
import {useAlert}                                  from "react-alert";
import {Pencil, Trash}                             from "react-bootstrap-icons";
import Spinner                                     from "components/shared/Spinner";
import SortableTable                               from "components/shared/SortableTable";
import YesNoModal                                  from "../../../components/shared/YesNoModal";
import CategoryModal                               from "./modals/CategoryModal";


const CategoryList = () => {
    const alert                                 = useAlert();
    const [categories, setCategories]           = useState([]);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [ready, setReady]                     = useState(false);
    const [showModal, setShowModal]             = useState(false);
    const [showDelete, setShowDelete]           = useState(false);
    const [flag, setFlag]                       = useState(true);

    const load = useCallback(() => {
        axios.get("/fm-kategoriens").then((response) => {
            setCategories(response.data);
            setReady(true);
        }).catch(error => {
            alert.error(`Fehler beim Laden der Kategorien: ${error}`)
        })
    },[alert])

    const del = () => {
        axios.delete(`fm-kategoriens/${currentCategory.id}`).then((res) => {
            load();
            setCurrentCategory(null);
            alert.success("Kategorie erfolgreich gelöscht");
        }).catch((error) => {
            alert.error("Fehler beim Löschen der Kategorie: ", error);
        });
    }

    const closeModal = () => {
        setShowModal(false);
        setFlag(false);
    }


    
    useEffect(() => {
        load();
    },[showModal, showDelete, load]);

    const Headers = [
        {
            label    : "Name",
            member   : "name",
            width    : "50%"
        },
        {
            label     : "",
            member    : false,
            sortable  : false,
            width     : "8%",
            functional: ({ member }) => {
                return (
                    <>
                        <button
                            className="btn"
                            onClick={() => {
                                setCurrentCategory(member);
                                setFlag(true);
                                setShowModal(true);
                            }}
                        >
                            <Pencil />
                        </button>
                        <button
                            className="btn"
                            onClick={() => {
                                setCurrentCategory(member);
                                setShowDelete(!showDelete);
                            }}
                        >
                            <Trash />
                        </button>
                    </>
                );
            },
        },
    ]
    
    if(categories.length === 0) {
        return(
            <>
                <h2 className="py-4">
                    Kategorien
                    <button className="btn btn-success btn-sm float-right" onClick={() => {
                        setFlag(false);
                        setShowModal(true);
                        }}>
                            Neue Kategorie anlegen
                    </button>
                </h2>
                <Spinner/>
                <CategoryModal label={"Kategorie"} closeModal={closeModal} show={showModal}/>
            </>
        )
    }

    return (
        <>
            <h2 className="py-4">
                Kategorien
                <button className="btn btn-success btn-sm float-right" 
                        onClick={() => {
                            setFlag(false);
                            setShowModal(true);
                        }}
                >
                    Neue Kategorie anlegen
                </button>
            </h2>
			{ready ?
				<>
					<SortableTable 
						data={categories}
						headers={Headers}
					/>
					<CategoryModal label={"Kategorie"} closeModal={closeModal} show={showModal} editFlag={flag} category={currentCategory} />
					<YesNoModal callback={()=>del()} show={showDelete} setShow={setShowDelete} text={`Soll die Kategorie ${currentCategory?.name} gelöscht werden?`} title="Kategorie löschen"/>
				</>	
			: null}
        </>
    )

}

export default CategoryList;