import React,{useState, useEffect} from "react";
import axios                       from "axios";
import {Form, Modal}               from "react-bootstrap";
import {useAlert}                  from "react-alert";



const SourceModal = ({label, show, closeModal, source, editFlag}) => {

    const alert = useAlert();

    const [formData, setFormData] = useState({
        name    : "",
        anonym  : false,
        email   : ""
    });

    const resetForm = () => {
        setFormData({
            name    : "",
            anonym  : false,
            email   : ""
        });
    }
    
    const handleInput = (event) => {
        setFormData({...formData, [event.currentTarget.name]: event.currentTarget.value})
    }

    const handleSwitch = (event) => {
        setFormData({...formData, [event.currentTarget.id]: event.currentTarget.checked})
    }

    const handleAbort = () => {
        resetForm();
        closeModal();
    }

    const handleSubmit = () => {
        if(!editFlag) {
            axios.post("/fm-quellens", formData).then(() => {
                alert.success("Quelle erfolgreich angelegt ");
            }).catch((error) => {
                alert.error("Fehler beim Anlegen: ", error);
            }).finally(() => {
                resetForm();
                closeModal();
            })
        } else {
            axios.put(`/fm-quellens/${formData.id}`, formData).then((res) => {
                alert.success("Quelle erfolgreich aktualisiert");
            }).catch((error) => {
                alert.error("Fehler beim Aktualisieren: ", error);
            }).finally(() => {
				resetForm();
                closeModal();
			})
        }
    }

    useEffect(() => {
        setFormData(source);
    },[source])

    return(
        <>
            <Modal show={show} size="lg" scrollable={true} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editFlag ? `Quelle bearbeiten` :`Neue ${label} anlegen`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <section className="grid form">
                        <label>Name</label>
                        <input className="form-control" name="name" value={formData?.name || ""}/>

                        <label>Anonym</label>
                        <Form.Check type="switch" id="anonym" onChange={handleSwitch} checked={formData?.anonym ? true : false}/>

                        <label>Email</label>
                        <input className="form-control" name="email" value={formData?.email || ""} onChange={handleInput} placeholder={"Emailadresse eingeben"}/>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-danger" onClick={handleAbort}>Abbrechen</button>
                    <button className="btn btn-success" onClick={handleSubmit}>Speichern</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SourceModal;