import React, {useContext, useState, useEffect} from "react";
import {NavLink, Route, Routes}                 from "react-router-dom";
import {StarHalf}                               from "react-bootstrap-icons";

import UserContext                              from "components/UserContext";

import FeedbackList                             from "./components/FeedbackList";
import CategoryList                             from "./components/CategoryList";
import SourcesList                              from "./components/SourcesList";
import RemarksList                              from "./components/RemarksList";

import "./_style.scss";

const FeedbackManager = ({type}) => {
	const {permissions} = useContext(UserContext);
	const [ready, setReady]       = useState(false)

	useEffect(() => {
		if (typeof permissions.application !== "undefined")
			setReady(true);
	}, [permissions]);

	if (!ready || !permissions.application.controllers["fm-feedback"].create.enabled)
		return <></>

	if (type === "nav")
		return <NavLink className={({isActive}) => isActive ? "active" : ""} to="/FeedbackManager"><StarHalf /> <span>Feedback Manager</span></NavLink>

	return (
		<>
			<nav>
				<ul className="nav nav-tabs">
					<li className="nav-item"><NavLink end className="nav-link" to="/FeedbackManager">Einsendungen</NavLink></li>
					<li className="nav-item"><NavLink end className="nav-link" to="gcategories">Kategorien</NavLink></li>
					<li className="nav-item"><NavLink end className="nav-link" to="gsources">Quellen</NavLink></li>
				</ul>
			</nav>
			<Routes>
				<Route index                             element={<FeedbackList/>} />
				<Route end path="gcategories"            element={<CategoryList/>} />
				<Route end path="gsources"               element={<SourcesList/>} />
				<Route end path="gfeedback/:id/remarks"  element={<RemarksList/>} />
			</Routes>
		</>
	);
};

export default FeedbackManager

