import React, { useState, useEffect, useCallback, useMemo } from "react";

import { useAlert }                                         from "react-alert";
import axios                                                from "axios";

import Spinner                                              from "components/shared/Spinner";
import SortableTable                                        from "components/shared/SortableTable";

import { ListTask, Trash, ListNested, X, PlusSquare, Pencil }         from "react-bootstrap-icons";

import DateInput                                            from "./../../../components/shared/DateInput";
import SearchField                                          from "./../../../components/shared/SearchField";
import Select                                               from "../../../components/shared/Select";
import YesNoModal 											from "../../../components/shared/YesNoModal";

// import FeedbackEdit                                         from "./modals/FeedbackEdit";
import FeedbackNew                                          from "./modals/FeedbackNew";

import EmailFormatter                                       from "../../../formatters/EmailFormatter";
import DateFormatter                                        from "../../../formatters/DateFormatter";
import { NavLink } from "react-router-dom";

const FeedbackList = () => {
	const alert                                 = useAlert();
	const [ready, setReady]                     = useState(false);
	const [feedbacks, setFeedbacks]             = useState([]);
	const [categories, setCategories]           = useState([]);
	const [sources, setSources]                 = useState([]);
	const [feedback, setFeedback]               = useState(null);
	// const [showEdit, setShowEdit]               = useState(false);
	const [showNew, setShowNew]                 = useState(false);
	const [showYesNo, setShowYesNo]				= useState(false);
	const [search, setSearch]                   = useState("");


	const [filterDateFrom, setFilterDateFrom]   = useState(null);
	const [filterDateUntil, setFilterDateUntil] = useState(null);
	const [filterCategory, setFilterCategory]   = useState("");
	const [filterSource, setFilterSource]       = useState("");
	const [filtered, setFiltered]               = useState([]);
	const [filterAnonym /*, setFilterAnonym*/]  = useState(false);


	const Headers = useMemo(() => [
		{
			label 		: "Feedback",
			member		: "feedback",
			width 		: "40%"
		},
		{
			label       : "Datum",
			member      : "createdAt",
			formatter   : DateFormatter
		},
		{
			label 		: "Quelle",
			member		: "fm_quellen.name",
			width 		: "10%"
		},
		{
			label 		: "Kategorie",
			functional	: ({member}) => {
				if(member?.fm_remarks?.length > 0) {
					return member?.fm_remarks?.map((remark, index) => {
						const found = categories?.find((category) => category.id === remark.fm_kategorien)
						return (
							<React.Fragment key={index}>
								<span key={index}>{`${found?.name || ""}`}</span>
								<br/>
							</React.Fragment>
						)
					})
				} else {
					return null;
				}
			}
		},
		{
			label       : "Status",
			functional  : ({member}) => {
				if(member?.fm_remarks?.length > 0) {
					/** Order of these find functions is  important */
					let found = undefined;
					found = member.fm_remarks.find(remark => remark.status === "Nicht_bearbeitet");
					if(found) {
						return (
							<>
								<span className="text-danger">Nicht bearbeitet</span>
							</>
						)
					}
					found = member.fm_remarks.find(remark => remark.status === "In_Bearbeitung");
					if(found) {
						return (
							<>
								<span className="text-warning">In Bearbeitung</span>
							</>
						)
					}
					found = member.fm_remarks.find(remark => remark.status === "Bearbeitet");
					if(found) {
						return (
							<>
								<span className="text-success">Bearbeitet</span>
							</>
						)
					}
				}
				return (
					<>
						<span className="text-danger">Nicht bearbeitet</span>
					</>
				)

			}
		},
		{
			label     	: "Info an",
			member    	: "fm_quellen.email",
			formatter 	: EmailFormatter,
		},
		{
			label     	: "",
			member    	: false,
			sortable  	: false,
			width		: "10%",		
			functional: ({ member }) => {
				return (
					<>
						{/* <button className="btn" onClick={() => {
							setShowEdit(true);
							setFeedback(member);
						}}>
							<Pencil/>
						</button> */}
						<NavLink to={`/FeedbackManager/gfeedback/${member.id}/remarks`}>
							<button className="btn">
								<ListTask />
							</button>
						</NavLink>
						<button
							className="btn"
							onClick={() => {
								setShowYesNo(true);
								setFeedback(member);
							}}
						>
							<Trash />
						</button>
					</>
				);
			},
		},
	], [categories]);

	const loadFeedbacks = useCallback(() => {

		const parameter = { _sort: "createdAt:desc"}
		axios
			.get("/fm-feedbacks", {params: parameter})
			.then((response) => {
				setFeedbacks(response.data);
				setFiltered(response.data);
				setReady(true);
			});
	}, []);

	const loadCategories = useCallback(() => {
		axios.get("/fm-kategoriens").then((response) => {
			setCategories(response.data);
		})
	}, []);

	const loadSources = useCallback(() => {
		axios.get("/fm-quellens").then((response) => {
			setSources(response.data);
		})
	}, []);

	const handleSelect = useCallback((event) => {
		switch(event.target.name) {
			case "category": 
			    setFilterCategory(event.target.value);
				break;
			case "source":
				setFilterSource(event.target.value);
				break;
			default:
				break;
		}
	}, []);

	const closeNewModal = () => {
		setShowNew(false);
	}

	const handleDelete = () => {
		axios.delete(`/fm-feedbacks/${feedback.id}`).then((res) => {
			setFeedback(null);
			loadFeedbacks();
			return alert.success("Feedback erfolgreich gelöscht.");
		}).catch((error) => {return alert.error(`Fehler beim Löschen des Feedbacks: ${error}`)})
	}

	useEffect(() => {
		loadFeedbacks();
		loadCategories();
		loadSources();
	}, [alert, loadFeedbacks, loadCategories, loadSources]);

	//Filtering
	useEffect(() => {
		setFiltered(feedbacks.filter((feedback) => {
			const date = new Date(feedback.createdAt);
			if(filterCategory !== "" && filterSource !== "" && filterDateFrom !== "" && filterDateUntil !== "") {
				return feedback?.fm_remarks?.fm_kategorien === filterCategory && feedback?.fm_quellen?.id === filterSource && (filterDateFrom <= date && date <= filterDateUntil);
			}
			if(filterCategory !== "") {
				return feedback?.fm_remarks?.some((remark) => {return remark.fm_kategorien === filterCategory})
			}
			if(filterSource !== "") {
				return feedback?.fm_quellen?.id === filterSource
			}
			if(filterDateFrom && filterDateUntil) {
				return (date >= filterDateFrom && date <= filterDateUntil)
			}
			if(filterDateFrom) {
				return date >= filterDateFrom 
			}
			if(filterDateUntil) {
				return date <= filterDateUntil
			}
			return feedbacks;
		}))
	},[filterCategory, filterSource, filterAnonym, filterDateFrom, filterDateUntil, feedbacks])

	//Text Search
	useEffect(() => {
		setFiltered(feedbacks.filter((feedback) => {
			const regex = new RegExp(`(${search}.*)`, "g");
			let valid = true;
			if (search.length > 0) {
				valid = feedback.feedback.match(regex) !== null;
			}
			return valid;
		}))
	},[search, feedbacks])

	return (
		<>
			<h2 className="py-4">Einsendungen</h2>
			<section className="filter-container">
				<div className="date-container">
				    <div className="date-picker">
						<div className="date-picker-inputGroup">
					        <label>Von</label>
					        <DateInput onChange={event => setFilterDateFrom(event.target.value === "" ? null : event.target.value)} value={filterDateFrom || ""} name="from"/>
							{
								filterDateFrom ? <button type="button" className="btn" onClick={() => setFilterDateFrom(null)}>
										<X/>
									</button>
								: null
							}
						</div>
				    </div>
				    <div className="date-picker">
						<div className="date-picker-inputGroup">
					        <label>Bis</label>
					        <DateInput onChange={event => setFilterDateUntil(event.target.value === "" ? null : event.target.value)} value={filterDateUntil || ""} name="until"/>
							{
							filterDateUntil ? <button type="button" className="btn" onClick={() => setFilterDateUntil(null)}>
										<X/>
									</button>
								: null
							}
						</div>
				    </div>
				</div>
				<div className="filter-category">
					<div className="filter-inputGroup">
						<label>Kategorie</label>
						<Select data={categories} defaultText="Kategorie auswählen" onChange={handleSelect} name="category" value={filterCategory} firstSelectable={true}/>
					</div>
					<div className="filter-inputGroup">
						<label>Quelle</label>
						<Select data={sources} defaultText="Quelle auswählen" onChange={handleSelect} name="source" value={filterSource} firstSelectable={true}/>
				</div>
					</div>
				<div className="search-container">
					<SearchField search={search} setSearch={setSearch}/>
				</div>
			</section>
			{ready ? (
				<>
				    <div className="btn-container">
					    <button className="btn btn-success" onClick={() => {setShowNew(!showNew)}}>
					        <PlusSquare/>
					    	Neues Feedback anlegen
					    </button>
					</div>
				    {filtered && filtered.length > 0 ?
				        <SortableTable
					        data={filtered}
					        headers={Headers}
				        /> :
					    <section className="noresult">
					        <ListNested />
					        Leider keine Ergebnisse
				        </section>
					}
				</>
			) : (
				<Spinner />
			)}
			{/* <FeedbackEdit
				currentFeedback={feedback}
				setCurrentFeedback={setFeedback}
				show={showEdit}
				setShow={setShowEdit}
				sources={sources}
				loadFeedbacks={loadFeedbacks}
			/> */}
			<FeedbackNew show={showNew} closeModal={closeNewModal} sources={sources} categories={categories} fetchData={loadFeedbacks}/>
			<YesNoModal show={showYesNo} setShow={setShowYesNo} text={"Soll das Feedback gelöscht werden?"} callback={handleDelete}/>
		</>
	);
};

export default FeedbackList;
