import React, {useState, useEffect, useMemo} from "react";
import axios from "axios";

import {Form, Modal} from "react-bootstrap";
import {useAlert}    from "react-alert";
import Select        from "../../../../components/shared/Select";

const RemarksEdit = ({show, setShow, currentFeedbackID, currentRemark, onHide, flag, setFlag, categories, loadFeedback}) => {

	const alert = useAlert();

	const [formComment, setFormComment] = useState({
		comment       : "",
		fm_feedback   : null,
		rating        : null,
		type          : null,
		status        : null,
		fm_kategorien : null,
	});

	const [category, setCategory] = useState();

	const resetForm = () => {
		setFormComment({
			comment       : "",
			fm_feedback   : null,
			rating        : null,
			type          : null,
			status        : null,
			fm_kategorien : null,
		})
	}

	const handleInput = (event) => {
		setFormComment({
			...formComment,
			[event.currentTarget.name]: event.currentTarget.value,
		});
	};

	const handleRadio = (event) => {
		switch (event.currentTarget.name) {
			case "rating-group":
				setFormComment({ ...formComment, rating: event.currentTarget.id });
				break;
			case "status-group":
				setFormComment({ ...formComment, status: event.currentTarget.id });
				break;
			default:
				break;
		}
	};

	const handleEvaluation = (event) => {
		setFormComment({ ...formComment, type: event.currentTarget.id });
	};

	const handleSelect = (event) => {
		let data = null;
		setCategory(event.currentTarget.value);
		data = { ...formComment };
		data[event.target.name] = event.target.value;
		setFormComment(data);
	};

	const submitComment = (event) => {
		if(!flag && formComment.comment !== "") {
			axios.post("/fm-remarks/" , {...formComment}).then(() => {
				loadFeedback();
				resetForm();
				setFlag(false);
				setShow(false);
				return alert.success("Bemerkung erfolgreich gespeichert");
			}).catch(error => {return alert.error(`Fehler beim Speichern der Bemerkung: ${error}`)});
		}
		if (flag && formComment.comment !== "") {
			axios.put(`/fm-remarks/${formComment.id}`, {...formComment}).then(() => {
				loadFeedback();
				resetForm();
				setFlag(false);
				setShow(false);
				return alert.success("Bemerkung erfolgreich aktualisiert");
			}).catch(error => {return alert.error(`Fehler beim Speichern der Bemerkung: ${error}`)});
		}
	} 

	useEffect(() => {
		if(flag) {
			setFormComment(currentRemark);
		} else {
			setFormComment({...formComment, fm_feedback: currentFeedbackID})
		}
	},[currentRemark, flag, currentFeedbackID])

	return(
		<>
			<Modal show={show} onHide={onHide} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>
						{flag ? "Bemerkung bearbeiten" : "Neue Bemerkung hinzufügen"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<section className="grid form">
						<label>Bemerkung</label>
						<textarea className="form-control" onChange={handleInput} name="comment" value={formComment?.comment || ""}/>

						<label>Einschätzung</label>
						<section className="row">
							<Form.Check style={{paddingRight: "1rem"}} type="radio" label="Lob" id="lob" onChange={handleEvaluation} checked={formComment?.type === "lob" ? true : false}/>
							<Form.Check type="radio" label="Kritik" id="kritik" onChange={handleEvaluation} checked={formComment?.type === "kritik" ? true : false}/>
						</section>

						<label>Risikobewertung</label>
						<section className="row">
							<Form.Check style={{paddingRight: "1rem"}} name="rating-group" type="radio" label="Niedrig" id="niedrig" onChange={handleRadio} checked={formComment?.rating === "niedrig" ? true : false}/>
							<Form.Check style={{paddingRight: "1rem"}} name="rating-group" type="radio" label="Normal" id="normal" onChange={handleRadio} checked={formComment?.rating === "normal" ? true : false}/>
							<Form.Check style={{paddingRight: "1rem"}} name="rating-group" type="radio" label="Hoch" id="hoch" onChange={handleRadio} checked={formComment?.rating === "hoch" ? true : false}/>
							<Form.Check type="radio" label="Kritisch" name="rating-group" id="kritisch" onChange={handleRadio} checked={formComment?.rating === "kritisch" ? true : false}/>
						</section>

						<label>Status</label>
						<section className="row">
							<Form.Check style={{paddingRight: "1rem"}} name="status-group" type="radio" label="Nicht Bearbeitet" id="Nicht_bearbeitet" onChange={handleRadio} checked={formComment?.status === "Nicht_bearbeitet" ? true : false}/>
							<Form.Check style={{paddingRight: "1rem"}} name="status-group" type="radio" label="In Bearbeitung" id="In_Bearbeitung" onChange={handleRadio} checked={formComment?.status === "In_Bearbeitung" ? true : false}/>
							<Form.Check style={{paddingRight: "1rem"}} name="status-group" type="radio" label="Bearbeitet" id="Bearbeitet" onChange={handleRadio} checked={formComment?.status === "Bearbeitet" ? true : false}/>
						</section>

						<label>Kategorien</label>
						<Select data={categories} value={formComment?.fm_kategorien || ""} onChange={handleSelect} defaultText="Kategorie auswählen..." name="fm_kategorien" firstSelectable={true}/>
					</section>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-outline-danger" onClick={() => {
						resetForm();
						setFlag(false);
						setShow(false);
					}}>
						Abbrechen
					</button>
					{
						flag ? 
							<button className="btn btn-success" onClick={submitComment}>
								Bemerkung aktualisieren
							</button>
						:	<button className="btn btn-success" onClick={submitComment}>
								Neue Bemerkung speichern
							</button>
					}
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default RemarksEdit;
