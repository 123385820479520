import React,{useState, useEffect} from "react";
import axios                       from "axios";
import {Modal}                     from "react-bootstrap";
import {useAlert}                  from "react-alert";



const CategoryModal = ({label, show, closeModal, editFlag ,category}) => {

    const alert = useAlert();

    const [formData, setFormData] = useState({
        name: ""
    });

    const resetForm = () => {
        setFormData({
            name: ""
        })
    }
    
    const handleInput = (event) => {
        setFormData({...formData, [event.currentTarget.name]: event.currentTarget.value})
    }

    const handleSubmit = () => {
        if(!editFlag) {
            axios.post("/fm-kategoriens", formData).then(() => {
                alert.success("Kategorie erfolgreich angelegt ");
            }).catch((error) => {
                alert.error("Fehler beim Anlegen: ", error);
            }).finally(() => {
                resetForm();
                closeModal();
            })
        } else {
            axios.put(`/fm-kategoriens/${formData.id}`, formData).then((res ) => {
                alert.success("Kategorie erfolgreich aktualisiert ");
            }).catch((error) => {
                alert.error("Fehler beim Aktualisieren: ", error);
            }).finally(() => {
                resetForm();
                closeModal();
            })
        }
    }

    useEffect(() => {
        setFormData(category);
    },[category]);

    return(
        <>
            <Modal show={show} size="lg"  onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editFlag ? `Kategorie bearbeiten` : `Neue ${label} anlegen`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <section className="grid form">
                        <label>Name</label>
                        <input className="form-control" name="name" onChange={handleInput} value={formData?.name || ""}/>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-outline-danger" onClick={() => {
                        resetForm();
                        closeModal();
                    }}>Abbrechen</button>
                    <button className="btn btn-success" onClick={handleSubmit}>Speichern</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CategoryModal;