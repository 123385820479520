import React, {useCallback, useState, useEffect} from "react";
import axios                                     from "axios";
import {useAlert}                                from "react-alert";

import {ChevronLeft, PlusSquare, Pencil, Trash, ListNested}  from "react-bootstrap-icons";
import {NavLink, useParams}                                             from "react-router-dom";

import SortableTable                             from "../../../components/shared/SortableTable"
import YesNoModal                                from "../../../components/shared/YesNoModal";
import DateFormatter                             from "../../../formatters/DateFormatter";
import EmailFormatter                            from "../../../formatters/EmailFormatter";
import PhoneNumberFormatter                      from "../../../formatters/PhoneNumberFormatter";

import RemarksEdit                               from "./modals/RemarksEdit";


const RemarksList = () => {
	
	const {id}  = useParams();
	const alert = useAlert();

	const [remarks, setRemarks]       		  = useState([]);
	const [categories, setCategories] 		  = useState([]);
	const [feedback, setFeedback]     		  = useState({});
	const [showYesNo, setShowYesNo]           = useState(false);
	const [showEdit, setShowEdit]             = useState(false);
	const [remark, setRemark]                 = useState(null);
	const [editFlag, setEditFlag]             = useState(false);

	const handleModalClose                    = (setter) => setter(false);
	
	const load = useCallback(() => {

		axios.get(`/fm-feedbacks/${id}`).then(response => {
			setFeedback(response.data);
			setRemarks(response.data.fm_remarks);
		}).catch(error => alert.error(`Fehler beim Laden des Feedbacks: ${error}`))
	},[id, alert])

	const loadCategories = useCallback(() => {
		axios.get("fm-kategoriens").then((response) => {
			setCategories(response.data);
		}).catch(error => alert.error(`Fehler beim Laden der Kategorien: ${error}`))
	},[alert]);

	const deleteRemark = () => {
		axios.delete(`fm-remarks/${remark.id}`).then(() => {
			load();
			setShowYesNo(false);
			alert.success("Bemerkung erfolgreich gelöscht");
		}).catch(error => alert.error(`Fehler beim Löschen der bemerkung: ${error}`));
	}

	useEffect(() => {
		load();
		loadCategories();
	},[load, alert])

	const headers = [
		{
			label  : "Bemerkung",
			member : "comment",
			width  : "35%",
		},
		{
			label      : "Rating",
			member     : "rating",
			functional : ({ member }) => {
				switch (member?.rating) {
					case "niedrig":
						return <span className="text-success">{member?.rating}</span>;
					case "normal":
						return <span className="text-normal">{member?.rating}</span>;
					case "hoch":
						return <span className="text-warning">{member?.rating}</span>;
					case "kritisch":
						return <span className="text-danger">{member?.rating}</span>;
					default:
						return null;
				}
			},
		},
		{
			label      : "Status",
			member     : "status",
			functional : ({ member }) => {
				switch (member?.status) {
					case "Nicht_bearbeitet":
						return (
							<span className="text-danger">
								{member?.status?.replace(/_/, " ")}
							</span>
						);
					case "In_Bearbeitung":
						return (
							<span className="text-warning">
								{member?.status?.replace(/_/, " ")}
							</span>
						);
					case "Bearbeitet":
						return (
							<span className="text-success">
								{member?.status?.replace(/_/, " ")}
							</span>
						);
					default:
						return null;
				}
			},
		},
		{
			label      : "Kategorien",
			member     : "fm_kategorien",
			functional : ({ member }) => {
				if (member?.fm_kategorien) {
					const found = categories.find(
						(category) => category.id === member?.fm_kategorien
					);
					return <span>{`${found?.name} `}</span>;
				} else {
					return null;
				}
			},
		},
		{
			label      : "",
			member     : false,
			sortable   : false,
			width      : "10%",
			functional : ({member}) => {
				return (
					<>
						<button className="btn"
							onClick={() => {
								setRemark(member);
								setEditFlag(true);
								setShowEdit(true);
							}}
						>
							<Pencil/>
						</button>
						<button className="btn"
							onClick={() => {
								setRemark(member);
								setShowYesNo(true);
							}}
						>
							<Trash/>
						</button>
					</>
				)
			}
		}
	]

    return(
        <>
			<h2 className="py-4">
				<NavLink to="/FeedbackManager">
					<button className="btn"><ChevronLeft className="big" /></button>
				</NavLink>
				Feedback
			</h2>
			<section className="grid half-half">
				<section className="grid form">
					<label>Vorname</label>
					<div>{`${feedback?.fm_reviewer?.firstName || ""}`}</div>
					
					<label>Nachname</label>
					<div>{`${feedback?.fm_reviewer?.lastName || ""}`}</div>

					<label>Nachricht</label>
					<div>{feedback?.feedback || ""}</div>

					<label>Rückantwort</label>
					<div>{!feedback?.answerType || feedback?.answerType === "noAnswer" ? "Keine Rückantwort erwünscht" : `${feedback?.answerType}`}</div>

					<label>Datum</label>
					<div>{DateFormatter(feedback?.createdAt)}</div>
				</section>
				
				<section className="grid form">
					<label>Quelle</label>
					<div>{feedback?.fm_quellen?.name || ""}</div>
					
					<label>Email</label>
					<div>{EmailFormatter(feedback?.fm_reviewer?.email || "")}</div>

					<label>Telefon/Handy</label>
					<div>{PhoneNumberFormatter(feedback?.fm_reviewer?.phone)}</div>

					<label>Straße, Hausnummer</label>
					<div>{`${feedback?.fm_reviewer?.address || ""} ${feedback?.fm_reviewer?.houseNumber || ""}`}</div>

					<label>Postleitzahl, Wohnort</label>
					<div>{`${feedback?.fm_reviewer?.zip || ""} ${feedback?.fm_reviewer?.city || ""}`}</div>
				</section>
			</section>
			<div className="btn-container">
				<button className="btn btn-success" onClick={() => {setEditFlag(false); setShowEdit(true); setRemark(remark)}}>
					<PlusSquare/>
					Neue Bemerkung hinzufügen
				</button>
			</div>
			{
				remarks.length > 0 ?
					<SortableTable data={remarks} headers={headers}/>
				:
					<section style={{fontSize: "2rem", 	background: "rgba(0, 0, 0, 0.1)", textAlign: "center", padding: "8rem 0"}}>
						<ListNested />
						Keine Bemerkungen vorhanden
					</section>
			}
			<YesNoModal show={showYesNo} setShow={setShowYesNo} text={"Soll die Bemerkung wirklich gelöscht werden?"} callback={deleteRemark}/>
			<RemarksEdit show={showEdit} setShow={setShowEdit} currentFeedbackID={id} currentRemark={remark} flag={editFlag} setFlag={setEditFlag} onHide={()=> handleModalClose(setShowEdit)} categories={categories} loadFeedback={load}/>
        </>
    )
}

export default RemarksList;