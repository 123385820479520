import axios                              from "axios";
import React, {useState}                  from "react";
import {useAlert}                         from "react-alert";
import {Modal, Form}                      from "react-bootstrap";
import DateFormatter                      from "../../../../formatters/DateFormatter";
import Select                             from "./../../../../components/shared/Select";


const FeedbackNew = ({show, closeModal, sources, categories, fetchData}) => {
	const alert                              = useAlert();

	const [formFeedback, setFormFeedback]    = useState({
		feedback: "",
		anonymous: null,
		fm_remarks: [],
		fm_reviewer: null,
		fm_kategorien: null
	});
	const [formComment, setFormComment]      = useState({
		comment: "",
		fm_feedback: null,
		rating: null,
		type: null,
		status: null,
		fm_kategorien: null
	});
	const [formReviewer, setFormReviewer]    = useState({
		firstName: "",
		lastName: "",
		email: "",
		fm_feedbacks: [],
		gender: null,
		phone: "",
		address: "",
		city: ""
	});
	
	const [source, setSource]                = useState("");
	const [category, setCategory]            = useState("");

	const handleSelect = (event) => {
		let data = null;
		switch(event.currentTarget.name) {
			case "fm_quellen":
				setSource(event.currentTarget.value);
				data = {...formFeedback};
				data[event.target.name] = event.target.value;
				setFormFeedback(data);
				break;
			case "fm_kategorien":
				setCategory(event.currentTarget.value);
				data = {...formComment};
				data[event.target.name] = event.target.value;
				setFormComment(data);
				break;
			default:
				break;
		}
	}

	const handleInput = (event) => {
		switch(event.currentTarget.name) {
			case "feedback":
				setFormFeedback({...formFeedback, [event.currentTarget.name]: event.currentTarget.value})
				break;
			case "comment":
				setFormComment({...formComment, [event.currentTarget.name]: event.currentTarget.value});
				break;
			default: 
			setFormFeedback({...formFeedback, [event.currentTarget.name]: event.currentTarget.value});
			break;
		}
	}

	const handleUserInfo = (event) => {
		switch(event.currentTarget.name) {
			case "houseNumber" || "zip": {
				setFormReviewer({...formReviewer, [event.currentTarget.name]: parseInt(event.currentTarget.value)})
				break;
			}
			default: {
				setFormReviewer({...formReviewer, [event.currentTarget.name]: event.currentTarget.value});
				break;
			}
		}
	}

	const handleRadio = (event) => {
		switch(event.currentTarget.name) {
			case "evaluation-group":
				setFormComment({...formComment, type: event.currentTarget.id });
				break;
			case "rating-group":
				setFormComment({...formComment, rating: event.currentTarget.id});
				break;
			case "gender-group":
				setFormReviewer({...formReviewer, gender: event.currentTarget.id})
				break;
			case "status-group":
				setFormComment({...formComment, status: event.currentTarget.id})
				break;
			default:
				break;
		}
	}


	const resetForms = () => {
		setFormFeedback(null);
		setFormComment(null);
		setFormReviewer(null);
	}

	/**
	 * different submit Methods for different routes
	 */
	const submitReviewer = async () => {
		if(formReviewer.firstName === "" || formReviewer.lastName === "" ) {
			return Promise.reject("Bitte einen Vor- und Nachnamen angeben")
		} else {
			return axios.post("/fm-members", formReviewer).then((res) => {
				return res;
			})
		}
	}

	const submitFeedback = async (reviewer) => {
		const postData = {
			...formFeedback,
			fm_reviewer: {
				...reviewer
			}
		};
		if(postData.feedback === "") {
			return Promise.reject("Bitte eine Nachricht angeben.");
		} else {
			return axios.post("/fm-feedbacks", postData).then((res) => {
				return res;
			});
		}
	}

	const submitComment = async (feedback) => {
		const postData = {
			...formComment, 
			fm_feedback: {
				...feedback
			}
		}
		return axios.post("/fm-remarks", postData).then((res) => {
			return res;
		});
	}

	/** Handling the actual submitting */
	const handleSubmit = async (event) => {
		event.preventDefault();
		const resReviewer = await submitReviewer().catch((error) => {return alert.error(`Fehler beim Erstellen des Reviewers: ${error}`)});
		if(resReviewer.options?.type !== "error") {
			const resFeedback =  await submitFeedback(resReviewer.data).catch((error) => {return alert.error(`Fehler beim Erstellen des Feedbacks: ${error}`)});
			if(resFeedback.options?.type !== "error" && formComment?.comment !== "") {
				await submitComment(resFeedback.data).catch((error) => {return alert.error(`Fehler beim Erstellen der Bemerkung: ${error}`)});
			}
			resetForms();
			fetchData();
			closeModal();
			return alert.success("Feedback erfolgreich angelegt.");

		}
	}

	return(
		<>
			<Modal show={show} scrollable={true} size="lg" onHide={closeModal}>
				<Modal.Header closeButton>
					<Modal.Title>Neues Feedback</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<section className="grid form">
						<label>Datum</label>
						<div>{DateFormatter(new Date())}</div>

						<label>Quelle</label>
						<Select data={sources} value={source} onChange={handleSelect} defaultText="Quelle auswählen für Benachrichtigung" name="fm_quellen"/>

						<label>Nachricht</label>
						<textarea className="form-control" onChange={handleInput} name="feedback" value={formFeedback?.feedback || ""}/>
					</section>

					<h4>Kontaktdaten</h4>
					<section className="grid form">
						<label>Anrede</label>
						<section className="row">
							<Form.Check type="radio" style={{"marginRight": "1rem"}} label="Herr" name="gender-group" id="M" onChange={handleRadio} checked={formReviewer?.gender === "M" ? true : false}/>
							<Form.Check type="radio" style={{"marginRight": "1rem"}} label="Frau" name="gender-group" id="W" onChange={handleRadio} checked={formReviewer?.gender === "W" ? true : false}/>
							<Form.Check type="radio" label="Divers" name="gender-group" id="Divers" onChange={handleRadio} checked={formReviewer?.gender === "Divers" ? true : false}/>
						</section>

						<label>Vorname</label>
						<input className="form-control" name="firstName" onChange={handleUserInfo} value={formReviewer?.firstName || ""} placeholder="Vorname eingeben..." required/>
						
						<label>Nachname</label>
						<input className="form-control" name="lastName" onChange={handleUserInfo} value={formReviewer?.lastName || ""} placeholder="Nachname eingeben..."/>

						<label>Straße, Hausnummer</label>
						<input className="form-control" name="address" onChange={handleUserInfo} value={formReviewer?.address || ""} placeholder="Adresse eingeben..."/>

						<label>Postleitzahl, Stadt</label>
						<input className="form-control" name="city" onChange={handleUserInfo} value={formReviewer?.city || ""} placeholder="Stadt eingeben..."/>

						<label>Email</label>
						<input className="form-control" name="email" onChange={handleUserInfo} value={formReviewer?.email || ""} placeholder="Email eingeben..."/>

						<label>Telefon</label>
						<input className="form-control" name="phone" onChange={handleUserInfo} value={formReviewer?.phone || ""} placeholder="Telefonnummer eingeben..."/>
					</section>
					<section className="grid form">
						<label>Bemerkung</label>
						<textarea className="form-control" onChange={handleInput} name="comment" value={formComment?.comment || ""} placeholder="Bemerkung eingeben..."/>

						<label>Einschätzung</label>
						<section className="row">
							<Form.Check type="radio" style={{"marginRight": "1rem"}} label="Lob" name="evaluation-group" id="lob" onChange={handleRadio} checked={formComment?.type === "lob" ? true : false}/>
							<Form.Check type="radio" style={{"marginRight": "1rem"}} label="Kritik" name="evaluation-group" id="kritik" onChange={handleRadio} checked={formComment?.type === "kritik" ? true : false}/>
						</section>

						<label>Risikobewertung</label>
						<section className="row">
							<Form.Check type="radio" style={{"marginRight": "1rem"}} onChange={handleRadio} checked={formComment?.rating === "niedrig" ? true : false} label="niedrig" name="rating-group" id="niedrig"/>
							<Form.Check type="radio" style={{"marginRight": "1rem"}} onChange={handleRadio} checked={formComment?.rating === "normal" ? true : false} label="normal"name="rating-group" id="normal"/>
							<Form.Check type="radio" style={{"marginRight": "1rem"}} onChange={handleRadio} checked={formComment?.rating === "hoch" ? true : false} label="hoch" name="rating-group" id="hoch"/>
							<Form.Check type="radio" onChange={handleRadio} checked={formComment?.rating === "kritisch" ? true : false} label="kritisch" name="rating-group" id="kritisch"/>
						</section>

						<label>Bearbeitungsstatus</label>
						<section className="row">
							<Form.Check type={"radio"} style={{"marginRight": "1rem"}} onChange={handleRadio} checked={formComment?.status === "Nicht_bearbeitet" ? true : false} label="Nicht bearbeitet" name="status-group" id="Nicht_bearbeitet"/>
							<Form.Check type={"radio"} style={{"marginRight": "1rem"}} onChange={handleRadio} checked={formComment?.status === "In_Bearbeitung" ? true : false} label="In Bearbeitung" name="status-group" id="In_Bearbeitung"/>
							<Form.Check type={"radio"} onChange={handleRadio} checked={formComment?.status === "Bearbeitet" ? true : false} label="Bearbeitet" name="status-group" id="Bearbeitet"/>
						</section>

						<label>Kategorie</label>
						<Select data={categories} value={category} onChange={handleSelect} defaultText="Kategorie auswählen..." name="fm_kategorien"/>
					</section>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-outline-danger" onClick={() => {
						resetForms();
						closeModal();
					}}>
						Abbrechen
					</button>
					<button className="btn btn-success" onClick={handleSubmit}>Speichern</button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default FeedbackNew;