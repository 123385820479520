import axios                        from "axios";
import React, {useState, useEffect} from "react";
import {Spinner}                    from "react-bootstrap";
import {Pencil, Trash}              from "react-bootstrap-icons";
import {useAlert}                   from "react-alert";
import EmailFormatter               from "../../../formatters/EmailFormatter";
import SortableTable                from "../../../components/shared/SortableTable";
import YesNoModal                   from "components/shared/YesNoModal";
import SourceModal                  from "./modals/SourceModal";

const SourcesList = () => {

    const alert = useAlert();

    const [sources, setSources]             = useState([]);
    const [showModal, setShowModal]         = useState(false);
    const [showDelete, setShowDelete]       = useState(false);
    const [currentSource, setCurrentSource] = useState(null);
    const [flag, setFlag]                   = useState(true);

    const fetchSources = ()  => {
        axios.get("fm-quellens").then((res) => {
            setSources(res.data);
        })
    }

    const closeModal = () => {
        setShowModal(false);
        setFlag(false)
    }

    const del = () => {
        axios.delete(`fm-quellens/${currentSource?.id}`).then((res) => {
            fetchSources();
            alert.success("Quelle erfolgreich gelöscht");
        }).catch((error) => {
            alert.error("Fehler beim Löschen der Quelle: ", error);
        });
    }

    useEffect(() => {
        fetchSources();
    },[showModal])

    const Headers = [
        {
            label    : "ID",
            member   : "id",
            width    : "10%"
        },
        {
            label    : "Name",
            member   : "name",
        },
        {
            label    : "Email",
            member   : "email",
            formatter: EmailFormatter,
        },
        {
            label     : "",
            member    : false,
            sortable  : false,
            width     : "8%",
            functional: ({ member }) => {
                return (
                    <>
                        <button
                            className="btn"
                            onClick={() => {
                                setCurrentSource(member);
                                setFlag(true);
                                setShowModal(true);
                            }}
                        >
                            <Pencil />
                        </button>
                        <button
                            className="btn"
                            onClick={() => {
                                setCurrentSource(member);
                                setShowDelete(!showDelete);
                            }}
                        >
                            <Trash />
                        </button>
                    </>
                );
            },
        },
    ]
    
    return(
        <>
            <h2 className="py-4">
                Quellen
                <button className="btn btn-success btn-sm float-right" 
                        onClick={() => {
                            setFlag(false);
                            setShowModal(true);
                        }}
                >
                    Neue Quelle anlegen
                </button>
            </h2>
            {
                sources.length === 0 ? 
                    <>
                        <Spinner/>
                        <SourceModal label={"Quelle"} closeModal={closeModal} show={showModal}/> 
                    </>
                : 
                <>
                    <SortableTable data={sources} headers={Headers}/>
                    <SourceModal label={"Quelle"} closeModal={closeModal} show={showModal} editFlag={flag} source={currentSource}/>
                    <YesNoModal callback={() => del()} show={showDelete} setShow={setShowDelete} text={`Soll die Quelle ${currentSource?.name} gelöscht werden?`} title="Quelle löschen"/>
                </>
            }
        </>
    )
}

export default SourcesList